/** @format */

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
}

body {
  overflow: hidden;
}

.small-font-table .ant-table-thead > tr > th, 
.small-font-table .ant-table-tbody > tr > td {
  font-size: 12px; 
}

.oneLineText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.twoLineText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}


.table-container .ant-table-wrapper {
  height: 100%;
  overflow: hidden;
}

.table-container .ant-table-wrapper .ant-spin-nested-loading {
  height: 100%;
}

.table-container .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.table-container .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
  max-height: 90%;
  overflow: auto;
}

/* .ant-menu-item-selected {
  border-radius: 0 !important;
  border-left: 5px solid #ffc83d;
  background-color: transparent;
} */

.custom-pagination {
  padding: 0px 16px;
}

::-webkit-scrollbar {
  display: none;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  /* display: block; */
}

::-webkit-scrollbar-thumb {
  display: none;
  height: 5px;
  width: 5px;
  background-color: rgba(21, 20, 26, 0.63);
  border-radius: 10px;
  /* display: block; */
}


.ant-tabs-tab-active .tab-link {
  color: #01A74E !important;
  font-weight: 500 !important;         
}
.custom-expand .ant-typography-expand, .ant-typography-collapse {
  color: #01A74E !important;  
  font-weight: 500;     
  cursor: pointer;        
}

@media (max-width: 768px) {
  .ant-picker-panel:last-child .ant-picker-date-panel {
    display: none !important;
  }
  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
  .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
    visibility: initial !important;
  }
  .ant-picker-panel.ant-picker-range-wrapper {
    min-width: 288px !important;
  }
  .ant-picker-presets {
    display: none !important;
  }
}